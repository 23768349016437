import EventBus from '@/plugins/event-bus'

const taskMixin = {
  methods: {
    taskCreated (taskId) {
      EventBus.$emit(
        'TASK',
        taskId
      )
    },
    taskFailed (error) {
      EventBus.$emit(
        'SNACKBAR',
        {
          color: 'error',
          message: this.$i18n.t('tasks.message.failed', { error }),
        }
      )
    },
  },
}

export default taskMixin
